import React from "react"
import H1 from "../../components/H1"
import EmailLink from "../../components/EmailLink"
import * as styles from "./styles.module.css"

const One = () => {
  return (
    <section className={styles.one}>
      <H1 text="ETF Stock Report Terms and Conditions"></H1>

      <div className={styles.grid}>
        <div>
          <h3>Effective: 2/09/2021</h3>

          <p>
            Please read and review ETF Stock Report Terms and Conditions
            (“Terms, “Terms and Conditions”) before using
            https://etfstockreport.com web site (the “Service” and “Site”) ETF
            Stock report is owned and operated by Celestial Creative Solutions,
            LLC. (“us”, “we””, ‘or “our”).
          </p>
          <p>
            The material on this web site, as well as documents, data, files,
            and other material contained within our published subscribers
            report, are the exclusive property of Celestial Creative Solutions,
            LLC. By its very nature, they are designated exclusively for the use
            by paid subscribers, and are safeguarded from sharing or illegal use
            by US and International Intellectual Property laws, copyright laws,
            trademark laws, and regulations. No copying, cloning, duplication,
            reduplication, proliferation, transmittal, or other unauthorized use
            of the data, images, graphs, material, publishing’s, or spreadsheets
            is permitted.
          </p>
          <p>
            Your admission and/or use of the Service is contingent upon your
            acknowledgement and conformity with these Terms contained in this
            document. If a customer, user, or visitor accesses this site on the
            behalf of a legally organized business establishment, such as a
            corporation or limited liability corporation, they are consenting to
            these terms and conditions on behalf of that business organization.
            These Terms apply to all visitors, users, and others who which to
            access or use the Site and Service. By entering and/or using the
            site you agree to be duty-bound by these Terms. If the user
            disagrees with any part of the terms, they should not use this site,
            nor do they have authorization to access the Service.
          </p>
          <p>
            The publisher reserves the right to change or modify these Terms and
            Conditions at any time. Visitors and subscribers can access the most
            up to date copy of these Terms and Conditions by clicking on the
            “Terms and Conditions” tab. If visitors and/or user disagree with
            any modifications to the Terms and Conditions they shall cease use
            of this web site at once. Accessing this Site and Service after the
            change in date of the most recent Terms and Conditions means consent
            to the modifications of the Terms and Conditions by visitors and
            users.
          </p>
          <p>
            ETF Stock Report analysis is derived from data believed to be
            accurate, but such accuracy or completeness cannot be guaranteed.
            The data and reports on this site do not claim to be comprehensive
            or to contain all the information which a prospective customer/user
            may need in order to make an investment decision. It should not be
            assumed that such analysis, past or future, will be profitable or
            will equal past performance, or guarantee future performance or
            results. All investment and trading investments are the sole
            responsibility of the customer/user. The publisher may update any
            research report as it determines appropriate. Each user of the
            research or report must make his or her own investigation and
            assessment of the information is presented.
          </p>
          <p>
            No responsibility, liability, or warranty of any kind will be
            accepted by Celestial Creative Solutions, LLC. or by any of its
            agents, advisers, employees, officers, or owners with regards to the
            relation of the accuracy or fulness of its publishing’s and/or
            reports presented on this Web Site or Service. Any individual or
            organization who uses these publishings and/or reports from this Web
            Site or Service does so at his or her own risk, as well as all
            accountability and liability for any loss, harm, or damages.
          </p>
          <p>
            We reserve the right to refuse service to anyone for any reason. An
            advisor/client relationship is not created by the distribution or
            delivery of the reports. ETF Stock Report and Celestial Creative
            Solutions LLC is not affiliated nor associated with Standard and
            Poor, Dow Jones, Nasdaq, nor CBOE-Chicago Board Options Exchange
            (VIX Index).{" "}
            <strong>
              The owners and principals of Celestial Creative Solutions, LLC may
              have open positions in the markets covered.
            </strong>
          </p>

          <h3>Accounts Introduction and Overview</h3>

          <p>
            By creating a subscription service account with our organization,
            the customer/user will be required to give us exact, current,
            complete, and truthful information at all times. This required data/
            information is the customer/user’s responsibility to update and keep
            current their data/information. Erroneous, inexact, or outdated
            data/ information may result in the direct baring from this site, in
            addition to possible dissolution and revocation of your account and
            access to the Site and Service.
          </p>
          <p>
            The customer/user will also be required to create a username and
            password in a manner determined by the Site. The customer/user shall
            not use a username in the name of another individual or
            organization. The customer/user shall not use a name or trademark
            that they are not legally authorized or entitled to use. The
            Customer/user shall not create or use a username that crude,
            distasteful, explicit, or insensitive. The Customer/user shall be
            accountable for preserving and maintaining the privacy of their
            account, password, and username. The Customer/User shall not divulge
            their account, username, or password to any other individual or
            organization. If the Customer/User has practical apprehensions that
            a third party is aware of the Customer/User’s account, username,
            and/or password they must notify etfstockreport.com at once by
            communicating via email at{" "}
            <EmailLink email="celestialcreativesolutions@gmail.com" />. If
            Celestial Creative Solutions, LLC. Has a plausible reasons or
            grounds for thinking that a Customer/User has mistreated their
            password or means to access their account, the Site may call for the
            Customer/User to change their password. The Owner may block the
            Customer/User from accessing the web site, including their account
            and subscriber’s area, until such a period the Owner is assured that
            the integrity and protection of the Site is no longer jeopardized by
            the Customer/User’s actions.
          </p>
          <p>
            An account is intended only for use by that particular individual
            Customer/User who has paid for the use of the Service. A
            Customer/User shall not share the means for entering their account
            including, but not limited to username and password. A Customer/User
            shall not share our published copyrighted material without getting
            our explicit permission, any unauthorized account sharing or
            transmissions of our material is breach of this agreement, and will
            be grounds for revocation and additional legal action. The
            Customer/User acknowledges obligation and responsibility for any and
            all actions, behaviors, and conduct that happens under their
            account, username, and/or password.
          </p>

          <h3>Account Monthly Subscriptions</h3>

          <p>
            The daily report, for paid subscribers, is available after the end
            of a market trading day. The current subscription model is billed on
            a per monthly basis. Monthly Subscriptions will recommence
            (auto-renew) at the end of a thirty- day billing cycle. The
            Customer/User shall provide the web site owners with full and
            accurate payment data, via a credit or debit card to create their
            account. The Customer/User, by using the paid subscription service
            consents to pay any subscription charges, at the amount for that
            period, in effect when the charges are billed. In the case of an
            unapproved payment, the web site retains the right to block and
            revoke the Customer/User access to the web site and/or subscription
            area. At the conclusion of each thirty-day billing cycle, your
            account subscription will recommence under the same particulars
            unless you rescind it, or Celestial Creative Solutions, LLC rescinds
            it.{" "}
            <strong>
              The Customer/User may cancel their subscription service via their
              account management page on the web site or by contacting Celestial
              Creative Solutions, LLC via email at{" "}
              <EmailLink email="celestialcreativesolutions@gmail.com" />.
            </strong>{" "}
          </p>

          <h3>Account Subscription Fees, Changes, and Refunds</h3>

          <p>
            The current monthly Subscription Fee is fifty dollars a month ($50
            USD) for access to the daily market report. The daily market report
            is published at the conclusion of each trading day. Celestial
            Creative Solutions, LLC., retains the full right and option, at any
            time to adjust the cost to access and maintain a subscription
            account. Celestial Creative Solutions, LLC., will give the
            Customer/User reasonable prior notice of any modifications to the
            Subscription fees. During this notice, of a fee changes, a
            Customer/User will have the option to cancel their Subscription
            before the new fees are active. Celestial Creative Solutions, LLC.,
            currently uses Stripe to process subscription payments. Celestial
            Creative Solutions, LLC., reserves the right to change the means to
            process subscription payments. Celestial Creative Solutions, LLC.,
            will give the Customer/User reasonable prior notice of any
            modifications of how payments are process.
          </p>
          <p>
            We do not offer refunds, including pro-rated refunds for
            Customer/Users. Customer/User’s monthly subscription service is
            active for a period of thirty-days. When the Customer/User cancels
            their account, their access will remain active until the next
            renewal date, then at the end of that thirty day period, they will
            not be charged for the next month, but their account access to the
            subscriber’s area is suspended.
          </p>

          <h3>Account & Web Site Security Provisions</h3>

          <p>
            The security and integrity of Customer/Users information is a
            priority for this Service. Celestial Creative Solutions, LLC.,
            endeavors to use commercially acceptable means to shield your
            personal information. We ask our Customer/Users to keep in mind that
            no means of electronic communication and storage is 100% safe and
            secure, we cannot pledge that there will be a total defense of
            communication and data. By accessing and using or site, the
            Customer/User shall not, nor permit others, expressly or implicitly
            to: (a) make an effort to or actually alter, change, damage,
            disorder, impede, or modify this Site or any data on this Site; or
            (b) make an effort to or actually compile any data on other persons
            and organizations, including but not limited to: account
            information, passwords, and usernames.
          </p>

          <h3>Governing Law</h3>

          <p>
            The laws of the United States and State of Florida govern these
            Terms and Conditions and the Customer/User use of this Site. The
            Customer/User consents to act in accordance with the all of the
            agreements, laws, regulations, and restrictions which apply to the
            Customer/User. No default, delay, or failure in implementing any
            right shall not be considered a waiver of those rights by our
            organization. In the event, that any of these stipulations are to be
            determined inapplicable, invalid, or illegal by a court of law, the
            remaining stipulations of these Terms and Conditions shall remain in
            effect. These Terms and Conditions establish the comprehensive
            arrangement between the Site with respect to our Subscription
            Service. The Customer/User acknowledges that they have not joined
            into these Terms and Conditions on the ground of any representation
            that is not explicated included in these Terms and Conditions. These
            Terms and Conditions replaces all prior arrangements that the
            Service might have with our Customers/Users. The Customer/User
            consents that courts located in the State of Florida have exclusive
            jurisdiction for any action, claim, or dispute under these Terms and
            Conditions. The Customer/User assents to using the to use the
            jurisdiction of the courts in the State of Florida.{" "}
          </p>

          <h3>Indemnity</h3>

          <p>
            The Customer/User assents to advocate, indemnify/requite, as well as
            to keep indemnified on a continuous basis, and hold the Web Site
            owner, operator, associates, subsidiaries, and their corresponding
            agents, contractors, directors, employees, information providers,
            licensor/ licensee,  officers, and owners harmless from and against
            any : costs, claims, damages, liability, losses, as well as, costs
            and expenses ( especially with regard to legal expenses and fees) as
            a result form the Customer/User’s access, behavior, connection,
            conduct, or use of this Service or any violation by the
            Customer/User of the these Terms and Conditions.
          </p>

          <h3>Intellectual Property</h3>

          <p>
            This Web Site and it’s Subscription Service and its data, models,
            appearance, performance, and process are and will continue to be the
            sole property of Celestial Creative Solutions, LLC. This web site
            and its subscription service are defended and safeguarded by
            copyright, trademarks, and other laws of the United States of
            America and foreign countries. Our data, graphs, publications,
            tables, and trademarks shall not be used in association or relation
            with any product or service without the clear written approval and
            consent of Celestial Creative Solutions, LLC.
          </p>

          <h3>Limitation of Liability</h3>

          <p>
            The Customer/User assents that by accessing and using this Web Site
            and Service is at his or her own risk. Neither the web site owner,
            operator, nor any other organization work to create, deliver,
            maintain, produce, or promote this Web Site and Service is
            responsible or liable for any accidental, direct, indirect, or
            punitive damages as a consequence of the Customer/User admittance
            to, use of, or failure to use this Web Site and Service. This also
            includes a connection to other web sites or the expense in acquiring
            a similar goods or services. The Customer/User explicitly assents
            that the downloading of any data or material from this Web Site and
            Service is done at the Customer/User’s own risk. Neither the web
            site owner, operator, nor any other organization work to create,
            deliver, maintain, produce, or promote this Web Site and Service is
            responsible or liable for any accidental, direct, indirect, or
            punitive damages as a consequence of the Customer/User downloading
            data, material, or reports from this Web Site or any other linked
            third party web site or service, comprising but not restricted to :
            damages, loss of profits, or other intangible property including
            legal costs and expenses, even though the Web Site owner and
            operator has been apprised of the chances of such damages. Without
            constraining the preceding, the sum of the material on the Web Site
            and Service is provided to the Customer/User “as it stands” and “as
            presented” without guarantee/ warranty of any type, express or
            implied, including but not limited to: fitness for a particular
            purpose or implied warranty of marketability/merchantability.
            Customer/Users should observe that some authorities and
            jurisdictions may not permit or restrict implied warranties, some of
            the restrictions may not pertain to every Customer/User.
          </p>

          <h3>Changes To Terms and Conditions</h3>

          <p>
            We retain the right to change/modify these Terms and Conditions at
            any time. We will communicate via email, as well as other means, to
            give you notice about any amendments, additions, changes, deletions,
            or revisions. The email and other communications we use to notify
            you, will be prior to revisions taking effect. Changes or
            modifications will take effect once we publish them on this section.
          </p>

          <h3>Contact Information</h3>

          <p>
            Please contact us, via email at
            <EmailLink email="celestialcreativesolutions@gmail.com" />, if you
            have questions regarding any of the provisions in these Terms and
            Conditions.
          </p>
        </div>
      </div>
    </section>
  )
}

export default One
