import React, { Fragment } from "react"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../termsPage/One"
import Footer from "../components/Footer"

const TermsPage = () => (
  <Fragment>
    <Helmet
      title="Terms & Conditions"
      descr="By accessing etfstockreport.com, usser is agreeing to the terms and conditions outlined herein."
      slug="/terms"
    />
    <Header />
    <One />
    <Footer />
  </Fragment>
)

export default TermsPage
